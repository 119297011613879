import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  verificationSideNav,
  secondaryListItemsDetails,
} from "../Side_Nav/listItems";
import { base_url1 } from "../../constants";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TextField } from "@material-ui/core";
import LoanDetails from "../Common/basicLoanDetails";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LogoutIcon from "@mui/icons-material/Logout";
import Tooltip from "@mui/material/Tooltip";
import Axios from "axios";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import LinearProgress from "@mui/material/LinearProgress";
import CloseIcon from "@mui/icons-material/Close";
import AppHeader from "./../Common/header";
import Footer from "./../Common/footer";
import { useNavigate } from "react-router-dom";
import SessionModal from "./../Common/SessionModal";
import {
  getCollateralVerificationRecord,
  createModifyCollateralRecord,
  getExternalAgencyMail,
  getCollateralDetails,
  sendCollateralVerificationEmail,
  updateCollateralDocument,
  getEmail,
  rerunBRE,
  getAllCollateralVerifications1,
  postLegalCollateral,
  postTechnicalCollateral,
  getDownloadUrl
} from "./../apiCalls/api";
import { useState } from "react";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const drawerWidth = 240;
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));
const columns1 = [
  {
    field: "type",
    headerName: "Collateral Type",
    flex: 1,
    minWidth: 90,
  },
  {
    editable: true,
    field: "primary_or_secondary",
    headerName: "Primary/Secondary",
    flex: 1,
    minWidth: 90,
    type: "singleSelect",
    valueOptions: ["Primary", "Secondary"],
    renderCell: (params) => {
      return (
        <div style={{ width: "100%", display: "contents" }}>
          <span style={{ width: "80%" }}>
            {params.row.primary_or_secondary}
          </span>
          <IconButton
            style={{ width: "20%" }}
            edge="end"
            color="inherit"
            aria-label="close"
          >
            <ExpandMoreIcon />
          </IconButton>
        </div>
      );
    },
  },
  {
    editable: true,
    field: "valuaton_type",
    headerName: "Valuaton Type",
    flex: 1,
    minWidth: 90,
  },
  {
    editable: true,
    field: "valuation_done_earlier",
    headerName: "Valuaton Done Earlier",
    flex: 1,
    minWidth: 90,
  },
  {
    editable: true,
    field: "date_previous_valuation",
    headerName: "Date of Previous Valuation",
    flex: 1,
    minWidth: 90,
  },
  {
    editable: true,
    field: "investigation",
    headerName: "Investigation Channel",
    flex: 1,
    minWidth: 90,
    type: "singleSelect",
    valueOptions: ["Internal", "External"],
    renderCell: (params) => {
      return (
        <div style={{ width: "100%", display: "contents" }}>
          <span style={{ width: "80%" }}>{params.row.investigation} </span>
          <IconButton
            style={{ width: "20%" }}
            edge="end"
            color="inherit"
            aria-label="close"
          >
            <ExpandMoreIcon />
          </IconButton>
        </div>
      );
    },
  },
  {
    editable: true,
    field: "agency_type",
    headerName: "External Agency Type",
    flex: 1,
    minWidth: 90,
  },
  {
    editable: true,
    field: "waiver",
    headerName: "Waiver",
    flex: 1,
    minWidth: 90,
    type: "boolean",
  },
  {
    editable: true,
    field: "reason",
    headerName: "Waiver Reason",
    flex: 1,
    minWidth: 90,
  },
  {
    editable: true,
    field: "decision",
    headerName: "FI Result",
    flex: 1,
    minWidth: 90,
    type: "singleSelect",
    valueOptions: ["Pass", "Initiation Pending", "Fail"],
    renderCell: (params) => {
      return (
        <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
          <Tooltip title={params.row.decision}>
            <span
              style={{
                width: "80%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {params.row.decision}
            </span>
          </Tooltip>
          <IconButton
            style={{ width: "20%" }}
            edge="end"
            color="inherit"
            aria-label="close"
          >
            <ExpandMoreIcon />
          </IconButton>
        </div>
      );
    },
  },
];
const columns2 = [
  {
    field: "type",
    headerName: "Collateral Type",
    flex: 1,
    minWidth: 90,
  },
  {
    editable: true,
    field: "primary_or_secondary",
    headerName: "Primary/Secondary",
    flex: 1,
    minWidth: 90,
    type: "singleSelect",
    valueOptions: ["Primary", "Secondary"],
    renderCell: (params) => {
      return (
        <div style={{ width: "100%", display: "contents" }}>
          <span style={{ width: "80%" }}>
            {params.row.primary_or_secondary}
          </span>
          <IconButton
            style={{ width: "20%" }}
            edge="end"
            color="inherit"
            aria-label="close"
          >
            <ExpandMoreIcon />
          </IconButton>
        </div>
      );
    },
  },
  {
    editable: true,
    field: "valuaton_type",
    headerName: "Valuaton Type",
    flex: 1,
    minWidth: 90,
  },
  {
    editable: true,
    field: "valuation_done_earlier",
    headerName: "Valuaton Done Earlier",
    flex: 1,
    minWidth: 90,
  },
  {
    editable: true,
    field: "date_previous_valuation",
    headerName: "Date of Previous Valuation",
    flex: 1,
    minWidth: 90,
  },
  {
    editable: true,
    field: "investigation",
    headerName: "Investigation Channel",
    flex: 1,
    minWidth: 90,
    type: "singleSelect",
    valueOptions: ["Internal", "External"],
    renderCell: (params) => {
      return (
        <div style={{ width: "100%", display: "contents" }}>
          <span style={{ width: "80%" }}>{params.row.investigation} </span>
          <IconButton
            style={{ width: "20%" }}
            edge="end"
            color="inherit"
            aria-label="close"
          >
            <ExpandMoreIcon />
          </IconButton>
        </div>
      );
    },
  },
  {
    editable: true,
    field: "agency_type",
    headerName: "External Agency Type",
    flex: 1,
    minWidth: 90,
  },
  {
    editable: true,
    field: "waiver",
    headerName: "Waiver",
    flex: 1,
    minWidth: 90,
    type: "boolean",
  },
  {
    editable: true,
    field: "reason",
    headerName: "Waiver Reason",
    flex: 1,
    minWidth: 90,
  },
  {
    editable: true,
    field: "decision",
    headerName: "FI Result",
    flex: 1,
    minWidth: 90,
    type: "singleSelect",
    valueOptions: ["Pass", "Initiation Pending", "Fail"],
    renderCell: (params) => {
      return (
        <div style={{ width: "100%", display: "contents" }}>
          <span style={{ width: "80%" }}>{params.row.decision} </span>
          <IconButton
            style={{ width: "20%" }}
            edge="end"
            color="inherit"
            aria-label="close"
          >
            <ExpandMoreIcon />
          </IconButton>
        </div>
      );
    },
  },
];

const mdTheme = createTheme();
const handleChangeInput = (event) => {};
function CollateralVerification() {
  const navigate = useNavigate();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [value, setValue] = React.useState(0);
  const [isVerified, setisVerified] = React.useState(false);
  const [basicDetails, setBasicDetails] = React.useState();
  const [mailBody, setmailBody] = React.useState("");
  const [isAPILoading, setisAPILoading] = React.useState(false);
  const [mailAttachment, setmailAttachment] = React.useState([]);
  const [userEmail, setuserEmail] = React.useState();
  const [downloadUrl, setDownloadUrl] = React.useState();
  const currentUser = useSelector((state) => state.currentUserDetails);
  const [rows, setRows] = React.useState([]);
  const reduxData = useSelector(
    (state) => state.applications?.loan_basic_info?.Loan_Application_id
  );
  const basicDetails1 = useSelector(
    (state) => state.applications?.company_collateral_details
  );
  const makerChecker = useSelector((state) => state.makerCheckAccess);
  const rows1 = [
    {
      id: 0,
      loan_id: reduxData,
      type: basicDetails1?.type_of_property,
      primary_or_secondary: "Primary",
      valuaton_type: "Legal",
      valuation_done_earlier: "N",
      waiver: false,
      agency_type: "",
      date_previous_valuation: "",
      reason: "",
      decision: "Initiation Pending",
      investigation: "External",
    },
    {
      id: 0,
      loan_id: reduxData,
      type: basicDetails1?.type_of_property,
      primary_or_secondary: "Secondary",
      valuaton_type: "Technical",
      valuation_done_earlier: "N",
      waiver: false,
      agency_type: "",
      date_previous_valuation: "",
      reason: "",
      decision: "Initiation Pending",
      investigation: "External",
    },
  ];
  useEffect(() => {
    setBasicDetails(basicDetails1);
    async function getData() {
      setisAPILoading(true);
      // getting data from database
      try {
        const result = await getCollateralVerificationRecord(reduxData);
        if (result.data.data.length == 0) {
          rows1.forEach(async (item) => {
            const result1 = await createModifyCollateralRecord(item);
            if (result1) {
              setRowsAPI();
            }
          });
        } else {
          // if there is no data in the database
          //setRows(result.data.data);
          const newList = result.data.data;
          newList.forEach((item) => {
            // if (item.waiver == 0) {
            //   item.waiver = false;
            // } else {
            //   item.waiver = true;
            // }
          });
          setRows(result.data.data);
        }
      } catch (error) {
        if (
          error.message === "AxiosError: Request failed with status code 401"
        ) {
          setisAPILoading(false);
          setIsErrorModalOpen(true);
          console.error(error);
        }
      }
      // try {
      //   const result = await getExternalAgencyMail(reduxData);
      //   if (!result.data.error) {
      //     setmailBody(result.data.bodyPreview);
      //     getDetails();
      //     setmailAttachment(result.data.URL);
      //     setisVerified(true);
      //     setisAPILoading(false);
      //   } else {
      //     setisVerified(false);
      //     setisAPILoading(false);
      //   }
      // } catch (error) {
      //   if (
      //     error.message === "AxiosError: Request failed with status code 401"
      //   ) {
      //     setisAPILoading(false);
      //     setIsErrorModalOpen(true);
      //     console.error(error);
      //   }
      // }
      try {
        const result = await getAllCollateralVerifications1(reduxData);
        console.log("legal and Technical records", result);
        setFinancialDetails(result.data.data.Technical);
        console.log("Technical Details", result.data.data.Technical);
        setlegalDetails(result.data.data.Legal);
      } catch (error) {
        if (
          error.message === "AxiosError: Request failed with status code 401"
        ) {
          setisAPILoading(false);
          setIsErrorModalOpen(true);
          console.error(error);
        }
      }
    }

    getData();
  }, [reduxData]);
  const getDetails = async () => {
    try {
      const result = await getCollateralDetails(reduxData);
      if (result.data) {
        // setFinancialDetails(result.data.db_data.technical);
        // setlegalDetails(result.data.db_data.legal);
      }
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setisAPILoading(false);
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    navigate("/login");
  };
  const setRowsAPI = async () => {
    setRows([]);
    try {
      const result = await getCollateralVerificationRecord(reduxData);
      if (result.data) {
        const newList = result.data.data;
        newList.forEach((item) => {
          // if (item.waiver == 0) {
          //   item.waiver = false;
          // } else {
          //   item.waiver = true;
          // }
        });
        setRows(result.data.data);
      }
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setisAPILoading(false);
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  const initiateverification = async () => {
    setisAPILoading(true);
    try {
      const result = await sendCollateralVerificationEmail(reduxData);
      setisAPILoading(false);
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setisAPILoading(false);
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  const handleEditCellChange = async (params) => {
    if (makerChecker) {
      setisAPILoading(true);
      try {
        const result = await createModifyCollateralRecord(params);
        setisAPILoading(false);
      } catch (error) {
        if (
          error.message === "AxiosError: Request failed with status code 401"
        ) {
          setisAPILoading(false);
          setIsErrorModalOpen(true);
          console.error(error);
        }
      }
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [open1, setOpen1] = React.useState(false);

  const handleClickOpen = () => {
    setOpen1(true);
  };
  const handleClose = () => {
    setOpen1(false);
  };
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const downloadAttachments = async () => {
    try {
        for (const item of mailAttachment) {
            try {
                const result = await getDownloadUrl(item);
                const downloadUrl = result.data.response_data.download_url;
                setDownloadUrl(downloadUrl);
                window.open(result.data.response_data.download_url, "_blank");
            } catch (error) {
                if (error.message) {
                  console.error("Error:", error);
                }
            }
        }
    } catch (error) {
        console.error("Error:", error);
    }
  }
  const [technicalCollateral, setFinancialDetails] = React.useState(null);
  const [legalCollateral, setlegalDetails] = React.useState({});
  const updateCollateralDetails = async () => {
    setisAPILoading(true);
    try {
      const result = await updateCollateralDocument(
        reduxData,
        legalCollateral,
        technicalCollateral
      );
      setisAPILoading(false);
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setisAPILoading(false);
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  const submitLegalCollateral = async () => {
    console.log(legalCollateral);
    let data = null;
    data = new FormData();
    data.append("legal_doc", "");
    data.append("loan_id", reduxData);
    data.append(
      "absolute_clear_marketable",
      legalCollateral.absolute_clear_marketable
    );
    data.append("mortgage_type", legalCollateral.mortgage_type);
    data.append("owner_nature", legalCollateral.owner_nature);
    data.append("valid_mortgage", legalCollateral.valid_mortgage);
    data.append(
      "prior_charges_against_property",
      legalCollateral.prior_charges_against_property
    );
    data.append(
      "original_latest_title_deeds_verified",
      legalCollateral.original_latest_title_deeds_verified
    );
    data.append("provision_applied", legalCollateral.provision_applied);
    try {
      const result = await postLegalCollateral(data);
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  const submitTechnicalCollateral = async () => {
    console.log(technicalCollateral);
    let data = null;
    data = new FormData();
    data.append("collateral_doc", "");
    data.append("loan_id", reduxData);
    data.append("address", technicalCollateral.address);
    data.append("city", technicalCollateral.city);
    data.append("classification", technicalCollateral.classification);
    data.append("collateral_category", technicalCollateral.collateral_category);
    data.append(
      "collateral_ownership",
      technicalCollateral.collateral_ownership
    );
    data.append("collateral_type", technicalCollateral.collateral_type);
    data.append("construction_cost", technicalCollateral.construction_cost);
    data.append("estimated_value", technicalCollateral.estimated_value);
    data.append("land_cost", technicalCollateral.land_cost);
    data.append("postal_code", technicalCollateral.postal_code);
    data.append("state", technicalCollateral.state);
    data.append("technical_remarks", technicalCollateral.technical_remarks);
    data.append("technical_status", technicalCollateral.technical_status);
    data.append("total_value", technicalCollateral.total_value);
    data.append("valuation_date", technicalCollateral.valuation_date);
    try {
      const result = await postTechnicalCollateral(data);
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  const rerunbre = async () => {
    setisAPILoading(true);
    try {
      const result = await getEmail(reduxData);
      if (result.data.length != 0) {
        setuserEmail(result.data[0].email_id);
        try {
          const rerunBre = await rerunBRE(reduxData, userEmail);
        } catch (error) {
          if (
            error.message === "AxiosError: Request failed with status code 401"
          ) {
            setisAPILoading(false);
            setIsErrorModalOpen(true);
            console.error(error);
          }
        }
      }
      setisAPILoading(false);
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setisAPILoading(false);
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  const handleInputChange = (e) => {
    setFinancialDetails({
      ...technicalCollateral,
      [e.target.name]: e.target.value,
    });
  };
  const downloadFile = async () => {
    try {
      const result = await getDownloadUrl(
        technicalCollateral.collateral_filepath
      );
      setDownloadUrl(result.data.response_data.download_url);
      window.open(result.data.response_data.download_url, "_blank");
    }catch (error) {
      if (error.message) {
        console.error("Error:", error);
      }
    }  
  };
  const downloadFileLegal = async () => {
    try {
      const result = await getDownloadUrl(
        legalCollateral.legal_filepath
      );
      setDownloadUrl(result.data.response_data.download_url);
      window.open(result.data.response_data.download_url, "_blank");
    }catch (error) {
      if (error.message) {
        console.error("Error:", error);
      }
    }  
  };
  const handleInputChangeLegal = (e) => {
    setlegalDetails({
      ...legalCollateral,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <ThemeProvider theme={mdTheme}>
      <div>
        {/* Your component content */}
        <SessionModal
          isOpen={isErrorModalOpen}
          onClose={handleCloseErrorModal}
        />
      </div>
      <Box sx={{ display: "flex", mb: 6 }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <AppHeader
              open={open}
              toggleDrawer={toggleDrawer}
              title="Collateral Verification"
            />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {verificationSideNav}
            <Divider />
            {currentUser.role_name === "credit_head" ||
            currentUser.role_name === "credit_ops" ? (
              <>{secondaryListItemsDetails}</>
            ) : null}
          </List>
        </Drawer>
        <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
          {isAPILoading ? (
            <Grid item xs={12}>
              <LinearProgress
                sx={{
                  color: "#3f48cc",
                }}
                size={40}
                thickness={4}
              />
            </Grid>
          ) : null}
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Initiate Verification"
                {...a11yProps(0)}
                style={{ textTransform: "capitalize" }}
              />
              {/* {isVerified ? (
                <Tab
                  label="Approve Verification"
                  {...a11yProps(1)}
                  style={{ textTransform: "capitalize" }}
                />
              ) : null} */}
            </Tabs>
          </Box>
          <div style={{ marginTop: "1%" }}>
            <LoanDetails></LoanDetails>
            <TabPanel value={value} index={0}>
              <div className="details-box">
                {rows ? (
                  <DataGrid
                    columns={columns1}
                    rows={rows}
                    processRowUpdate={handleEditCellChange}
                    onEditCellChange={handleEditCellChange}
                    editMode="cell"
                    style={{ height: "45vh" }}
                  />
                ) : null}
              </div>
              {basicDetails != undefined ? (
                <div className="details-box">
                  <Grid item xs={12} sx={{ pt: 4 }}>
                    <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
                      <Grid item xs={12} sm={6} md={6}>
                        <Typography
                          sx={{ pt: 2, pb: 2 }}
                          component="div"
                          style={{ fontWeight: "800", color: "#3f48cc" }}
                        >
                          Basic Details
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                        style={{
                          width: "100%",
                        }}
                      >
                        <Button
                          disabled={!makerChecker}
                          sx={{ textTransform: "none" }}
                          variant="contained"
                          className="download-button"
                        >
                          View Document
                        </Button>
                        {/* <Button
                          sx={{ textTransform: "none" }}
                          variant="contained"
                          className="download-button"
                        >
                          Upload Document
                          <input
                            hidden
                            ref={inputRef}
                            type="file"
                          />
                        </Button> */}
                      </Grid>
                      {Object.entries(basicDetails).map(([key, value]) => (
                        <Grid key={key} item xs={12} sm={6} md={6} mt={3}>
                          <TextField
                            fullWidth
                            type="text"
                            variant="outlined"
                            name="estimatedValue"
                            label={key
                              .replace(/_/g, " ")
                              .toLowerCase()
                              .split(" ")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(" ")}
                            value={value}
                          />
                        </Grid>
                      ))}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                      style={{
                        width: "100%",
                      }}
                    >
                      <Button
                        disabled={!makerChecker}
                        sx={{ textTransform: "none" }}
                        variant="contained"
                        className="download-button"
                        onClick={initiateverification}
                      >
                        Initiate Verification
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              ) : null}
              {technicalCollateral !== undefined &&
                technicalCollateral !== null &&
                (Object.keys(technicalCollateral).length != 0 ? (
                  <div className="details-box">
                    <Grid item xs={12} sx={{ pt: 4 }}>
                      <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            sx={{ mr: 4, flex: 1, pt: 2, pb: 2 }}
                            component="div"
                            style={{ fontWeight: "800", color: "#3f48cc" }}
                          >
                            Technical Collateral
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          style={{
                            width: "100%",
                          }}
                        >
                          <Button
                            sx={{ textTransform: "none" }}
                            variant="contained"
                            className="download-button"
                            disabled={
                              technicalCollateral.collateral_filepath == ""
                            }
                            onClick={downloadFile}
                          >
                            Download Document
                          </Button>
                        </Grid>
                        {Object.entries(technicalCollateral).map(
                          ([key, value]) => (
                            <>
                              {key != "collateral_filepath" ? (
                                <Grid
                                  key={key}
                                  item
                                  xs={12}
                                  sm={6}
                                  md={6}
                                  mt={3}
                                >
                                  <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    name={key}
                                    label={key
                                      .replace(/_/g, " ")
                                      .toLowerCase()
                                      .split(" ")
                                      .map(
                                        (word) =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1)
                                      )
                                      .join(" ")}
                                    value={
                                      isNaN(value)
                                        ? value
                                        : Number(value).toLocaleString()
                                    }
                                    onChange={(e) => {
                                      const newValue = e.target.value.replace(
                                        /,/g,
                                        ""
                                      ); // Remove commas
                                      handleInputChange({
                                        target: {
                                          name: key,
                                          value: newValue,
                                        },
                                      });
                                    }}
                                  />
                                </Grid>
                              ) : null}{" "}
                            </>
                          )
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                      style={{
                        width: "100%",
                      }}
                    >
                      <Button
                        sx={{ textTransform: "none" }}
                        disabled={currentUser.role_name != "credit_head"}
                        variant="contained"
                        className="download-button"
                        onClick={submitTechnicalCollateral}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </div>
                ) : null)}
              {legalCollateral !== undefined &&
                legalCollateral !== null &&
                (Object.keys(legalCollateral).length != 0 ? (
                  <div className="details-box">
                    <Grid item xs={12} sx={{ pt: 4 }}>
                      <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            sx={{ mr: 4, flex: 1, pt: 2, pb: 2 }}
                            component="div"
                            style={{ fontWeight: "800", color: "#3f48cc" }}
                          >
                            Legal Collateral
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          style={{
                            width: "100%",
                          }}
                        >
                          <Button
                            sx={{ textTransform: "none" }}
                            variant="contained"
                            className="download-button"
                            disabled={legalCollateral.legal_filepath == ""}
                            onClick={downloadFileLegal}
                          >
                            Download Document
                          </Button>
                        </Grid>
                        {Object.entries(legalCollateral).map(([key, value]) => (
                          <>
                            {key != "legal_filepath" ? (
                              <Grid key={key} item xs={12} sm={6} md={6} mt={3}>
                                <TextField
                                  fullWidth
                                  type="text"
                                  variant="outlined"
                                  name={key}
                                  label={key
                                    .replace(/_/g, " ")
                                    .toLowerCase()
                                    .split(" ")
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                    )
                                    .join(" ")}
                                  value={value}
                                  onChange={(e) => handleInputChangeLegal(e)}
                                />
                              </Grid>
                            ) : null}
                          </>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                      style={{
                        width: "100%",
                      }}
                    >
                      <Button
                        sx={{ textTransform: "none" }}
                        disabled={currentUser.role_name != "credit_head"}
                        variant="contained"
                        className="download-button"
                        onClick={submitLegalCollateral}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </div>
                ) : null)}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {rows ? (
                <div className="details-box">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    style={{
                      width: "100%",
                    }}
                  >
                    <Button
                      sx={{ textTransform: "none" }}
                      variant="contained"
                      className="download-button"
                      onClick={handleClickOpen}
                    >
                      View Email
                    </Button>
                    <BootstrapDialog
                      className="mailCSS"
                      onClose={handleClose}
                      aria-labelledby="customized-dialog-title"
                      open={open1}
                    >
                      <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleClose}
                      >
                        External Agency Email Details
                        <Grid item xs={12} sm={6} md={6} mt={2}>
                          <Typography
                            sx={{ mr: 4, flex: 1 }}
                            component="div"
                            style={{ fontWeight: "800" }}
                          >
                            Mail Body
                          </Typography>
                          <TextField
                            fullWidth
                            type="text"
                            variant="outlined"
                            name="Personal_Discussion"
                            value={mailBody}
                            inputProps={{
                              style: {
                                height: "150px",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} mt={2}>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                            style={{
                              width: "100%",
                            }}
                          >
                            {/* <Button
                              sx={{ textTransform: "none" }}
                              variant="contained"
                              className="download-button"
                            >
                              View Attachments
                            </Button> */}
                            <Button
                              sx={{ textTransform: "none" }}
                              variant="contained"
                              className="download-button"
                              onClick={downloadAttachments}
                            >
                              Download Attachments
                            </Button>
                          </Grid>
                        </Grid>
                      </BootstrapDialogTitle>
                      <DialogContent></DialogContent>
                      {/* <DialogActions>
                        <Button
                          autoFocus
                          className="download-button"
                          onClick={handleClose}
                        >
                          Close
                        </Button>
                      </DialogActions> */}
                    </BootstrapDialog>
                  </Grid>
                  <DataGrid
                    columns={columns2}
                    rows={rows}
                    processRowUpdate={handleEditCellChange}
                    onEditCellChange={handleEditCellChange}
                    editMode="cell"
                    style={{ height: "45vh" }}
                  />
                </div>
              ) : null}
              {/* {technicalCollateral != undefined ? (
                <div className="details-box">
                  <Grid item xs={12} sx={{ pt: 4 }}>
                    <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          sx={{ mr: 4, flex: 1, pt: 2, pb: 2 }}
                          component="div"
                          style={{ fontWeight: "800", color: "#3f48cc" }}
                        >
                          Technical Collateral
                        </Typography>
                      </Grid>
                      {Object.entries(technicalCollateral).map(
                        ([key, value]) => (
                          <Grid key={key} item xs={12} sm={6} md={6} mt={3}>
                            <TextField
                              fullWidth
                              type="text"
                              variant="outlined"
                              name={key}
                              label={key
                                .replace(/_/g, " ")
                                .toLowerCase()
                                .split(" ")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(" ")}
                              value={value}
                              onChange={(e) => handleInputChange(e)}
                            />
                          </Grid>
                        )
                      )}
                    </Grid>
                  </Grid>
                </div>
              ) : null}
              {legalCollateral != undefined ? (
                <div className="details-box">
                  <Grid item xs={12} sx={{ pt: 4 }}>
                    <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          sx={{ mr: 4, flex: 1, pt: 2, pb: 2 }}
                          component="div"
                          style={{ fontWeight: "800", color: "#3f48cc" }}
                        >
                          Legal Collateral
                        </Typography>
                      </Grid>

                      {Object.entries(legalCollateral).map(([key, value]) => (
                        <Grid key={key} item xs={12} sm={6} md={6} mt={3}>
                          <TextField
                            fullWidth
                            type="text"
                            variant="outlined"
                            name={key}
                            label={key
                              .replace(/_/g, " ")
                              .toLowerCase()
                              .split(" ")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(" ")}
                            value={value}
                            onChange={(e) => handleInputChangeLegal(e)}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </div>
              ) : null} */}
              <Grid item xs={12} sm={12} md={12} mt={2}>
                {/* <div className="details-box">
                  <table className="mracTable table table-bordered text-center table-sm">
                    <thead>
                      <tr>
                        <th scope="col">S. No</th>
                        <th scope="col">Type of Document</th>
                        <th scope="col">Upload</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr></tr>
                      <tr></tr>
                      <tr>
                        <td>1</td>
                        <td>
                          <select
                            name="verification"
                            id="verification"
                            className="form-control input100"
                          >
                            <option defaultValue value="office">
                              Photos
                            </option>
                            <option value="residential">Document</option>
                          </select>
                        </td>
                        <td>
                          <input type="file" />
                        </td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>
                          <select
                            name="verification"
                            id="verification"
                            className="form-control input100"
                          >
                            <option defaultValue value="office">
                              Photos
                            </option>
                            <option value="residential">Document</option>
                          </select>
                        </td>
                        <td>
                          <input type="file" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div> */}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                style={{
                  width: "100%",
                }}
              >
                <Button
                  disabled={!makerChecker}
                  sx={{ textTransform: "none" }}
                  variant="contained"
                  className="download-button"
                  onClick={updateCollateralDetails}
                >
                  Approve
                </Button>
                <Button
                  disabled={!makerChecker}
                  sx={{ textTransform: "none" }}
                  variant="contained"
                  className="download-button"
                  onClick={rerunbre}
                >
                  Re-Run BRE
                </Button>
              </Grid>
            </TabPanel>
          </div>
        </Box>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}

export default function collateralverification() {
  return <CollateralVerification />;
}
