import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Select from "react-select";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  verificationSideNav,
  secondaryListItemsDetails,
} from "../Side_Nav/listItems";
import Button from "@mui/material/Button";
import { TextField } from "@material-ui/core";
import LoanDetails from "../Common/basicLoanDetails";
import LinearProgress from "@mui/material/LinearProgress";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppHeader from "./../Common/header";
import Footer from "./../Common/footer";
import { useNavigate } from "react-router-dom";
import SessionModal from "./../Common/SessionModal";
import { CreateModifyPI, getPersonalInterview } from "./../apiCalls/api";
import { useState } from "react";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();
const handleChangeInput = (event) => {};
function Discussion() {
  const navigate = useNavigate();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [value, setValue] = React.useState(0);
  const [open1, setOpen1] = React.useState(false);
  const [comments, setComments] = React.useState("");
  const [isAPILoading, setisAPILoading] = React.useState(false);
  const [missingDocuments, setmissingDocuments] = React.useState("");
  const currentUser = useSelector((state) => state.currentUserDetails);
  const reduxData = useSelector(
    (state) => state.applications?.loan_basic_info?.Loan_Application_id
  );
  const options = [
    { value: "BankStatement", label: "Bank Statement" },
    { value: "GSTDocument", label: "GST Document" },
    { value: "FinancialStatement", label: "Financial Statement" },
  ];
  const options1 = [
    { value: "BankStatement", label: "Bank Statement" },
    { value: "SalarySlip", label: "Salary Slip" },
    { value: "ITRDocument", label: "ITR Document" },
    { value: "Passport/Aadhar", label: "Passport/Aadhar" },
    { value: "PAN", label: "PAN" },
    { value: "BusinessLicence", label: "Business Licence" },
    { value: "BusinessVintage", label: "Business Vintage" },
  ];
  const [selectedOptions, setSelectedOptions] = useState([]);
  const loanType = useSelector(
    (state) => state.applications?.loan_basic_info?.loan_type
  );
  const handleChangeDropdown = (selected) => {
    setSelectedOptions(selected);
  };
  const makerChecker = useSelector((state) => state.makerCheckAccess);
  useEffect(() => {
    async function getData() {
      setisAPILoading(true);
      try {
        const result = await getPersonalInterview(reduxData);
        if (result) {
          setisAPILoading(false);
          setComments(result.data.data[0].description);
        }
      } catch (error) {
        if (
          error.message === "AxiosError: Request failed with status code 401"
        ) {
          setisAPILoading(false);
          setIsErrorModalOpen(true);
          console.error(error);
        }
      }
    }
    getData();
  }, [reduxData]);
  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    navigate("/login");
  };
  const submitForm = async () => {
    setisAPILoading(true);
    let optionsString = ""; // Initialize the optionsString

    if (selectedOptions.length > 0) {
      optionsString = selectedOptions.map((option) => option.label).join(", ");
      setmissingDocuments(optionsString); // Update the state
    }
    try {
      const result = await CreateModifyPI(reduxData, comments, optionsString);
      if (result) {
        setisAPILoading(false);
      }
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setisAPILoading(false);
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  const handleInputChange = (e) => {
    setComments(e.target.value);
  };
  const handleClick = () => {
    setOpen1(!open1);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <div>
        {/* Your component content */}
        <SessionModal
          isOpen={isErrorModalOpen}
          onClose={handleCloseErrorModal}
        />
      </div>
      <Box sx={{ display: "flex", mb: 6 }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <AppHeader
              open={open}
              toggleDrawer={toggleDrawer}
              title="Personal Interview"
            />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {verificationSideNav}
            <Divider />
            {currentUser.role_name === "credit_head" ||
            currentUser.role_name === "credit_ops" ? (
              <>{secondaryListItemsDetails}</>
            ) : null}
          </List>
        </Drawer>
        <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
          {isAPILoading ? (
            <Grid item xs={12}>
              <LinearProgress
                sx={{
                  color: "#3f48cc",
                }}
                size={40}
                thickness={4}
              />
            </Grid>
          ) : null}
          <div style={{ marginTop: "1%" }}>
            <LoanDetails></LoanDetails>
            <div className="details-box">
              {loanType == "Individual" ? (
                <div>
                  <h6>Select Missing Document (if any):</h6>
                  <Select
                    isMulti
                    options={options1}
                    value={selectedOptions}
                    onChange={handleChangeDropdown}
                  />
                </div>
              ) : (
                <div>
                  <h6>Select Missing Document (if any):</h6>
                  <Select
                    isMulti
                    options={options}
                    value={selectedOptions}
                    onChange={handleChangeDropdown}
                  />
                </div>
              )}
              <Grid item xs={12} sm={6} md={6} mt={2}>
                <Typography
                  sx={{ mr: 4, flex: 1, pb: 2 }}
                  component="div"
                  style={{ fontWeight: "800" }}
                >
                  Approval Conditions
                </Typography>
                <TextField
                  fullWidth
                  multiline // Add this attribute to enable multi-line input
                  rows={4} // You can adjust the number of rows as needed
                  variant="outlined"
                  name="Personal_Discussion"
                  value={comments}
                  onChange={(e) => handleInputChange(e)}
                  inputProps={{
                    style: {
                      minHeight: "100px", // Use minHeight instead of height for multiline
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                style={{
                  width: "100%",
                }}
              >
                <Button
                  disabled={!makerChecker}
                  sx={{ textTransform: "none" }}
                  variant="contained"
                  className="download-button"
                  onClick={submitForm}
                >
                  Submit
                </Button>
              </Grid>
            </div>
          </div>
        </Box>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}

export default function discussion() {
  return <Discussion />;
}
